import React from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Section from '../components/section';
import ServiceCard from '../components/service-card';

export default function DoctorTemplate({ data }) {
  const pageInfo = {
    id: 'doctor-page',
    url: `/team/${data.strapiDoctor.slug}`,
    title: data.strapiDoctor.name,
    description: data.strapiDoctor.description,
    keywords: data.strapiDoctor.keywords,
    image: data.strapiDoctor.avatar.publicURL,
    breadcrumbs: [
      {
        title: 'Главная',
        link: '/',
      },
      {
        title: 'Наш коллектив',
        link: '/team',
      },
      {
        title: data.strapiDoctor.name,
        link: null,
      },
    ],
  };

  const pagesByRows = [[]];

  if (data.strapiDoctor.services.length > 0) {
    const services = [...data.strapiDoctor.services];

    services.sort((a, b) => {
      if (a.weight === null) return 1;
      if (b.weight === null) return -1;
      if (a.weight > b.weight) return 1;
      if (a.weight < b.weight) return -1;
      return 0;
    });

    for (let i = 0, j = 0; i < services.length; i += 1) {
      if (!(j < 4)) {
        pagesByRows.push([]);
        j = 0;
      }

      pagesByRows[pagesByRows.length - 1].push(services[i]);
      j += 1;
    }
  }

  return (
    <Layout pageInfo={pageInfo}>
      <Section>
        <div className="doctor-avatar">
          <GatsbyImage
            image={data.strapiDoctor.avatar.childImageSharp.gatsbyImageData}
            alt={data.strapiDoctor.name}
          />
        </div>
        <article>
          <ReactMarkdown>
            {data.strapiDoctor.article}
          </ReactMarkdown>
          {
            pagesByRows[0].length > 0
              ? (
                <React.Fragment>
                  <h2>
                    Оказываемые услуги
                  </h2>
                  {
                    pagesByRows.map((row) => (
                      <div className="row clearfix">
                        {
                          row.map((page) => (
                            <div className="column column--1-of-4">
                              <ServiceCard service={{ ...page }} />
                            </div>
                          ))
                        }
                      </div>
                    ))
                  }
                </React.Fragment>
              )
              : null
          }
        </article>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query($strapiId: Int!) {
    strapiDoctor(strapiId: { eq: $strapiId }) {
      strapiId
      slug
      name
      description
      keywords
      weight
      avatar {
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
      article
      services {
        slug
        title
        description
        weight
        cover {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
