import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import './service-card.css';

export default function ServiceCard({ service }) {
  return (
    <a href={`/services/${service.slug}`}>
      <div className="service-card">
        <div className="service-card__cover">
          <GatsbyImage
            image={service.cover.childImageSharp.gatsbyImageData}
            alt={service.title}
          />
        </div>
        <div className="service-card__title">
          <h3>
            {service.title}
          </h3>
        </div>
        <div className="service-card__description">
          {service.description}
        </div>
      </div>
    </a>
  );
}
